import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class ProfileModule extends AppModule {
    /* eslint-disable max-len */ // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
    addRoutes(router) {
        let topBar;
        topBar = void 0;
        router.addProfileRoute({
            name: RouteName.PROFILE,
            path: '/profile',
            component: ()=>import('web/src/modules/profile/pages/UserProfileRoutePage/UserProfileRoutePage.vue'),
            title: '{{$t(\'JSPACC_ACC_TLT\')}}',
            topBar,
            isTabBarVisible: true,
            topBarType: void 0
        });
        router.addProfileRoute({
            name: RouteName.CUSTOMER_SETTINGS,
            path: '/profile/settings',
            component: ()=>import('web/src/modules/profile/pages/UserSettingsRoutePage/UserSettingsRoutePage.vue'),
            title: '{{$t(\'JSPNAV_USTAT_SETTINGS\')}}'
        });
        router.addProfileRoute({
            name: RouteName.CUSTOMER_VERIFICATION,
            path: '/profile/settings/verify',
            component: ()=>import('web/src/modules/profile/submodules/verification/pages/VerificationRoutePage.vue'),
            topBar: ()=>import('web/src/modules/profile/submodules/verification/components/CustomerVerificationTopBarRouteComponent.vue')
        });
        router.addProfileRoute({
            name: RouteName.CUSTOMER_VERIFICATION_SELECT_TIME,
            path: '/profile/settings/verify/select-time',
            component: ()=>import('web/src/modules/profile/submodules/verification/components/Video/CustomerVerificationSelectTimeRoute.vue'),
            title: '{{$t(\'WEB2_VERIFY_PAGE_TITLE\')}}'
        });
        router.addModalRoute({
            name: RouteName.CUSTOMER_VERIFICATION_CALL,
            path: '/profile/settings/verify/call',
            component: ()=>import('web/src/modules/profile/submodules/verification/components/Video/CustomerVerificationVideoCall.vue'),
            topBar: ()=>import('web/src/modules/profile/submodules/verification/components/CustomerVerificationVideoCallTopBar.vue'),
            title: '{{$t(\'WEB2_VERIFY_PAGE_TITLE\')}}',
            modalPreset: ModalDesktopPreset.LargeDesktopModal
        });
        router.addProfileRoute({
            name: RouteName.CUSTOMER_PERSONAL_DATA,
            path: '/profile/settings/personal-data',
            component: ()=>import('web/src/modules/profile/pages/PersonalDataRoutePage/PersonalDataRoutePage.vue'),
            title: '{{$t(\'WEB2_PERSONAL_DATA\')}}'
        });
        router.addProfileRoute({
            name: RouteName.PASSWORD_CHANGE,
            path: '/profile/settings/password-change',
            component: ()=>import('web/src/modules/profile/pages/PasswordChangeRoutePage/PasswordChangeRoutePage.vue'),
            title: '{{$t(\'JS_PASSCHANGE_MOBILE\')}}'
        });
        router.addProfileRoute({
            name: RouteName.CUSTOMER_SUBSCRIPTIONS,
            path: '/profile/settings/subscriptions',
            component: ()=>import('web/src/modules/profile/pages/CustomerSubscriptionsRoutePage/CustomerSubscriptionsRoutePage.vue'),
            title: '{{$t(\'WEB2_MY_SUBSCRIPTIONS\')}}'
        });
        router.addProfileRoute({
            name: RouteName.CMS_VERIFICATION_REQUIREMENTS,
            path: '/customer-verification-requirements',
            component: ()=>import('web/src/modules/cms/components/VCmsContent/VCmsContent.vue'),
            props: {
                cmsKey: 'WEB2_VERIFICATION_REQUIREMENTS'
            },
            title: '{{$t(\'WEB2_VERIFY_REQUIREMENTS_TITLE\')}}'
        });
    }
}
