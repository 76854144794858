// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal_info__title_xn_t2{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:0 0 2px;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}html.app__layout--desktop .personal_info__title_xn_t2{max-width:155px}.personal_info__subtitle_Sqfxd{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}html.app__layout--desktop .personal_info__subtitle_Sqfxd{cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personal_info__title": `personal_info__title_xn_t2`,
	"personal_info__subtitle": `personal_info__subtitle_Sqfxd`
};
export default ___CSS_LOADER_EXPORT___;
