// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cbc-balance_ei0fL{cursor:pointer;background-color:var(--Layer1)}.cbc-balance__icon_SbWq9{color:var(--ColorContent4)}.cbc-balance__wrapper_i7en3{display:flex;gap:4px;align-items:flex-start;align-self:stretch;justify-content:space-between}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cbc-balance": `cbc-balance_ei0fL`,
	"cbc-balance__icon": `cbc-balance__icon_SbWq9`,
	"cbc-balance__wrapper": `cbc-balance__wrapper_i7en3`
};
export default ___CSS_LOADER_EXPORT___;
