// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rg-bet-top-bar--light_upoDr{position:relative}.rg-bet-top-bar--light_upoDr:before{position:absolute;top:0;right:0;bottom:0;left:0;content:"";background-color:var(--ColorBackground2)}.rg-bet-top-bar__info_YvbQN{z-index:1;display:inline-flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center}.rg-bet-top-bar__title_Xp7qg{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}.rg-bet-top-bar__balance_BOthN{font-size:11px;font-weight:400;line-height:16px;color:var(--ColorContent1a)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rg-bet-top-bar--light": `rg-bet-top-bar--light_upoDr`,
	"rg-bet-top-bar__info": `rg-bet-top-bar__info_YvbQN`,
	"rg-bet-top-bar__title": `rg-bet-top-bar__title_Xp7qg`,
	"rg-bet-top-bar__balance": `rg-bet-top-bar__balance_BOthN`
};
export default ___CSS_LOADER_EXPORT___;
