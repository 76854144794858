import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
import useResponsibleGamblingTopBar from 'web/src/modules/profile/submodules/responsible-gambling/components/ResponsibleGamblingTopBar/composables/useResponsibleGamblingTopBar';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResponsibleGamblingTopBar',
    props: {
        title: {},
        isDepositLimit: {
            type: Boolean
        },
        isBetLimit: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { infoButtonProperties, closeButtonProperties, isLightBackground, balanceValue, onClick, onClose } = useResponsibleGamblingTopBar(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                class: _normalizeClass({
                    [_ctx.$style['rg-bet-top-bar--light']]: _unref(isLightBackground)
                })
            }, {
                title: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['rg-bet-top-bar__info'])
                        }, [
                            _createVNode(VDynamicContent, {
                                tag: _unref(Tag).SPAN,
                                class: _normalizeClass(_ctx.$style['rg-bet-top-bar__title']),
                                content: _ctx.title
                            }, null, 8, [
                                "tag",
                                "class",
                                "content"
                            ]),
                            _unref(isLightBackground) ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['rg-bet-top-bar__balance'])
                            }, _toDisplayString(_ctx.$t('JSPACC_ACC_BALANCE')) + " : " + _toDisplayString(_unref(balanceValue)), 3)) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                suffix: _withCtx(()=>[
                        (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 1
                        }, _unref(infoButtonProperties), {
                            onClick: _unref(onClick)
                        }), null, 16, [
                            "onClick"
                        ])),
                        (_openBlock(), _createBlock(VButton, _mergeProps({
                            key: 2
                        }, _unref(closeButtonProperties), {
                            onClick: _unref(onClose)
                        }), null, 16, [
                            "onClick"
                        ]))
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'ResponsibleGamblingTopBar'
                ]
            ]);
        };
    }
});
